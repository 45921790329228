'use client';

import { useCallback, useRef, useReducer } from 'react';
import { useSearchParams } from 'next/navigation';
import { useCurrentUtm } from './useCurrentUtm';

import enrollmentConfig from '@/config/enrollment-lps';

type UrlParams = Record<string, string>;

type UpdateUrlProps = {
  initialUrl?: string;
  params?: UrlParams;
  clientKey?: string;
  productKey?: string; // product solution slug: thrive, bloom, etc
};

export function isEnrollmentHostname(url: string) {
  try {
    const urlObj = new URL(url);
    const enrollmentUrlObj = new URL(enrollmentConfig.url);
    // there are still some instances in the CMS where the old enrollment URL is used
    const enrollmentOldUrlObj = new URL(enrollmentConfig.oldUrl);

    return (
      urlObj.hostname === enrollmentUrlObj.hostname ||
      urlObj.hostname === enrollmentOldUrlObj.hostname
    );
  } catch {
    return false;
  }
}

export default function useUrlWithParams(props?: UpdateUrlProps): [
  string | undefined,
  // eslint-disable-next-line no-unused-vars
  (params: UpdateUrlProps) => void,
] {
  const searchParams = useSearchParams();
  const prevUrl = useRef<string | null>();
  const utmParams = useCurrentUtm();

  const getUrl = useCallback(
    ({ initialUrl = enrollmentConfig.url, params = {}, clientKey }: UpdateUrlProps) => {
      try {
        const isExternal = new URL(initialUrl).hostname !== window.location.hostname;

        if (!isExternal && !isEnrollmentHostname(initialUrl)) {
          prevUrl.current = initialUrl;
          return initialUrl;
        }

        const url = new URL(initialUrl);
        const initialUrlParams = Object.fromEntries(url.searchParams);

        // client inheritance order
        // function attribute params -> window.location search params -> initialUrl search params
        const client = clientKey || searchParams.get('client') || initialUrlParams?.client;

        // product inheritance order
        // function attribute params -> window.location search params -> initialUrl search params
        const product = clientKey || searchParams.get('product') || initialUrlParams?.product;

        // utm inheritance order
        // function attribute params -> window.location search params -> initialUrl search params
        const urlParams = {
          ...utmParams,
          ...initialUrlParams,
          ...params,
        };

        for (const key in urlParams) {
          url.searchParams.set(key, urlParams[key]);
        }

        if (product || client) {
          // set client and product in the pathname
          // but ensure only 1 product path is present
          url.pathname = `${product ? product : url.pathname}${client ? `/${client}` : ''}`.replace(
            /\/{2,}/g,
            '/',
          );
        }

        const newUrl = url.toString();

        prevUrl.current = newUrl;
        return newUrl;
      } catch {
        prevUrl.current = initialUrl;
        return initialUrl;
      }
    },
    [searchParams, utmParams],
  );

  const [url, updateUrl] = useReducer(
    (initialUrl, updateParams) => getUrl({ initialUrl, ...updateParams }),
    getUrl({
      initialUrl: props?.initialUrl,
      clientKey: props?.clientKey,
      productKey: props?.productKey,
    }),
  );

  // update state on props change - equivalent to the the behaviour of getDerivedStateFromProps
  // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  if (url && prevUrl?.current !== url) {
    updateUrl({
      initialUrl: props?.initialUrl,
      clientKey: props?.clientKey,
      productKey: props?.productKey,
    });
  }

  return [url, updateUrl];
}
