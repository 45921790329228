'use client';

import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '@/utils/styles-variables';
import { trackNavigation } from '@/utils/track';

function trackLink(target) {
  trackNavigation({
    url: target.href,
    location: target.closest('[data-theme]').id,
    label: target.innerText,
  });
}

const Template = ({ children }) => {
  useEffect(() => {
    function setWindowWidth() {
      document.documentElement.style.setProperty(
        '--window-width',
        `${document.documentElement.clientWidth}px`,
      );
    }
    setWindowWidth();

    const componentLinks = document.querySelectorAll('[data-theme] a');

    function trackLinks() {
      componentLinks.forEach((link) => {
        link.addEventListener('click', () => trackLink(link));
      });
    }

    function cleanupLinks() {
      componentLinks.forEach((link) => {
        link.removeEventListener('click', () => trackLink(link));
      });
    }

    trackLinks();
    window.addEventListener('resize', () => setWindowWidth());

    return () => {
      window.removeEventListener('resize', () => setWindowWidth());
      cleanupLinks();
    };
  }, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Template;
