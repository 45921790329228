import { sword } from '@swordhealth/ui-corporate/themes';

// TODO: Remove this after the migration to the new theme is done
const defaultColors = {
  dark_neutral: '#EFE9E6',
};

const theme = {
  ...sword,
  colors: {
    ...defaultColors,
    ...sword.colors,
    inputs: {
      bg: sword.colors.white,
      placeholder: 'rgba(48, 38, 29, 0.5)',
      text: sword.colors.grey[800],
      border: sword.colors.grey[500],
      borderFocus: sword.colors.grey[800],
      error: sword.colors.negative.default,
    },
  },
  font: {
    ...sword.font,
    family: {
      body: 'var(--ui-font-family-body)',
      heading: 'var(--ui-font-family-heading)',
    },
  },
  breakpoints: {
    ...sword.breakpoints,
    // Legacy breakpoints
    // TODO: Remove this after the migration to wow factor is done
    max_xs: 479,
    min_sm: 480,
    max_sm: 767,
    min_md: 768,
    max_md: 991,
    min_lg: 992,
    max_lg: 1199,
    min_xl: 1200,
    max_xl: 1399,
    min_xxl: 1400,
  },
};

export default theme;
