import { trackEvent } from '@/lib/segment';
import { isEnrollmentHostname } from './useUrlWithParams';

export const ACTIONS = {
  CLICK_ENROLL: 'click_enroll',
  DEMO_REQUEST: 'demo_request',
  NAVIGATION: 'navigation',
  TRACK: 'track',
  CLICK_FAQ: 'open_faq',
  CAREERS_APPLY: 'careers_apply',
};

export const BUTTON_TYPES = {
  NAVIGATION: 'navigation',
  SUBMIT: 'submit',
};

export const EVENT_NAMES = {
  BUTTON_CLICKED: 'Button Clicked',
  DEMO_REQUESTED: 'Demo Requested',
  NEWSLETTER_SUBSCRIBED: 'Newsletter Subscribed',
  EVENT_SUBSCRIBED: 'Event Subscribed',
  FAQ: 'FAQ Clicked',
  CAREERS_APPLIED: 'Applied to Job',
  // https://segment.com/docs/connections/spec/video
  VIDEO_PLAY_START: 'Video Playback Started',
  VIDEO_PAUSE: 'Video Playback Paused',
  VIDEO_PLAY_RESUME: 'Video Playback Resumed',
  VIDEO_COMPLETED: 'Video Playback Completed',
  VIDEO_EXITED: 'Video Playback Exited',
  FILE_DOWNLOAD: 'File Downloaded',
};

export function trackNavigation({ url, location, label, pathname }) {
  if (!url) {
    return;
  }

  if (isEnrollmentHostname(url)) {
    trackButtonClicked({
      action: ACTIONS.CLICK_ENROLL,
      buttonLocation: location,
      buttonText: label,
      buttonType: BUTTON_TYPES.NAVIGATION,
      destination: url,
      url: pathname,
    });

    return;
  }

  if (url.includes('/request-demo')) {
    trackButtonClicked({
      action: ACTIONS.DEMO_REQUEST,
      buttonLocation: location,
      buttonText: label,
      buttonType: BUTTON_TYPES.NAVIGATION,
      destination: url,
      url: pathname,
    });

    return;
  }

  trackButtonClicked({
    action: ACTIONS.NAVIGATION,
    buttonText: label,
    buttonType: BUTTON_TYPES.NAVIGATION,
    buttonLocation: location,
    destination: url,
    url: pathname,
  });
}

export function trackButtonClicked({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  destination = '',
  url,
  pageTitle = '',
}) {
  let title = pageTitle;

  if (document && !pageTitle) {
    title = document.title;
  }

  trackEvent(EVENT_NAMES.BUTTON_CLICKED, {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    page_url: url,
    destination,
    page_title: title,
  });
}

export function trackDemoRequested(properties) {
  trackEvent(EVENT_NAMES.DEMO_REQUESTED, properties);
}

export function trackEventSubscribed(properties) {
  trackEvent(EVENT_NAMES.EVENT_SUBSCRIBED, properties);
}

export function trackNewsletterSubscribed(properties) {
  trackEvent(EVENT_NAMES.DEMO_REQUESTED, properties);
}

export function trackCareersApplied(properties) {
  trackEvent(EVENT_NAMES.CAREERS_APPLIED, {
    ...properties,
    page_title: document.title,
    action: ACTIONS.CAREERS_APPLY,
  });
}

export function trackFaqClicked(properties) {
  trackEvent(EVENT_NAMES.FAQ, {
    ...properties,
    page_title: document.title,
    action: ACTIONS.CLICK_FAQ,
  });
}

export function trackVideoPlayed(event, { sessionId, length, src, ...properties }) {
  trackEvent(event, {
    ...properties,
    video_player: 'local',
    action: ACTIONS.TRACK,
    content_asset_id: src,
    total_length: length,
    session_id: sessionId,
  });
}

export function trackDownload({ pageTitle, pageUrl, fileUrl, automatic }) {
  let title = pageTitle;

  if (document && !pageTitle) {
    title = document.title;
  }

  trackEvent(EVENT_NAMES.FILE_DOWNLOAD, {
    automatic,
    file: fileUrl,
    action: ACTIONS.TRACK,
    page_title: title,
    page_url: pageUrl,
  });
}
