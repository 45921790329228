'use client';

import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

/**
 * Declare our returned type
 *
 * @typedef {Object} UtmParamsFiltered
 * @property {string} [utm_source]
 * @property {string} [utm_medium]
 * @property {string} [utm_campaign]
 * @property {string} [utm_content]
 */

const validUtmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

/*
 * Declare default UTM params
 */
const defaultUTM = {
  utm_medium: 'referral',
  utm_source: 'sword_website',
};

/**
 *
 * @param {URLSearchParams} params
 * @returns {UtmParamsFiltered}
 */
export function geUrlParams(params) {
  const utm = defaultUTM;

  if (!params) {
    return utm;
  }

  params.forEach((value, key) => {
    if (key && validUtmParams.includes(key)) {
      utm[key] = value;
    }
  });

  return utm;
}

/**
 *
 * @returns {UtmParamsFiltered}
 */
export function useCurrentUtm() {
  const searchParams = useSearchParams();

  return useMemo(() => {
    return geUrlParams(searchParams);
  }, [searchParams]);
}
